export enum RemoteConfigParameter {
    CreatePdfReportButtonVisible = 'createPdfReportButtonVisible',
    CountryDropdownByCountry = 'countryDropdownByCountry',
    CountryLanguageSwitcherByCountry = 'countryLanguageSwitcherByCountry',
    PdfConfiguratorBySection = 'pdfConfiguratorBySection',
    PdfConfiguratorGeneralInformationBySubsection = 'pdfConfiguratorGeneralInformationBySubsection',
    PdfConfiguratorHeatPumpPlanBySubsection = 'pdfConfiguratorHeatPumpPlanBySubsection',
    PdfConfiguratorPhotovoltaicPlanBySubsection = 'pdfConfiguratorPhotovoltaicPlanBySubsection',
    PdfConfiguratorVentilationPlanBySubsection = 'pdfConfiguratorVentilationPlanBySubsection',
    PdfConfiguratorFullSystemBySubsection = 'pdfConfiguratorFullSystemBySubsection',
    PdfConfiguratorLegalNoticeBySubsection = 'pdfConfiguratorLegalNoticeBySubsection',
    PdfConfiguratorPitchDeckByLocale = 'pdfConfiguratorPitchDeckByLocale',
    PdfConfiguratorDocumentationTabVisible = 'pdfConfiguratorDocumentationTabVisible',
    DomesticElectricityDayNightTariffByCountry = 'domesticElectricityDayNightTariffByCountry',
    HeatPumpElectricityFlatRateByCountry = 'heatPumpElectricityFlatRateByCountry',
    HeatPumpElectricityDayNightTariffByCountry = 'heatPumpElectricityDayNightTariffByCountry',
    HeatPumpElectricityBlockTimeByCountry = 'heatPumpElectricityBlockTimeByCountry',
    HeatPumpElectricityBlockTimeDisabledByCountry = 'heatPumpElectricityBlockTimeDisabledByCountry',
    OnboardingVersion = 'onboardingVersion'
}
