/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HeatGeneratorsResponseGetRecordsList } from '../../models/heat-generators-response-get-records-list';

export interface DevicesControllerGetHeatGenerators$Params {

/**
 * ISO standard Alpha-2 country code.The building address country code.
 */
  countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

/**
 * Optional parameter denoting if the endpoint is used in GEG Module or not.
 *         The provided value will also inform about the specific GEG flow, which should be taken into
 *         account when returning the data.
 */
  gegSystemType?: 'NEW' | 'CURRENT' | 'HYBRID';
}

export function devicesControllerGetHeatGenerators(http: HttpClient, rootUrl: string, params: DevicesControllerGetHeatGenerators$Params, context?: HttpContext): Observable<StrictHttpResponse<HeatGeneratorsResponseGetRecordsList>> {
  const rb = new RequestBuilder(rootUrl, devicesControllerGetHeatGenerators.PATH, 'get');
  if (params) {
    rb.path('countryCode', params.countryCode, {});
    rb.query('gegSystemType', params.gegSystemType, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<HeatGeneratorsResponseGetRecordsList>;
    })
  );
}

devicesControllerGetHeatGenerators.PATH = '/planning-projects/v1-develop/devices/heat-generators/{countryCode}';
