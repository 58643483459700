/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreateProjectResponse } from '../models/create-project-response';
import { DeleteProjectResponse } from '../models/delete-project-response';
import { DuplicateProjectResponse } from '../models/duplicate-project-response';
import { FinalizeProjectResponse } from '../models/finalize-project-response';
import { LockProjectResponse } from '../models/lock-project-response';
import { ProjectResponseGetById } from '../models/project-response-get-by-id';
import { projectsControllerCreateProject } from '../fn/projects-controller/projects-controller-create-project';
import { ProjectsControllerCreateProject$Params } from '../fn/projects-controller/projects-controller-create-project';
import { projectsControllerDeleteProject } from '../fn/projects-controller/projects-controller-delete-project';
import { ProjectsControllerDeleteProject$Params } from '../fn/projects-controller/projects-controller-delete-project';
import { projectsControllerDuplicateProject } from '../fn/projects-controller/projects-controller-duplicate-project';
import { ProjectsControllerDuplicateProject$Params } from '../fn/projects-controller/projects-controller-duplicate-project';
import { projectsControllerFinalizeProject } from '../fn/projects-controller/projects-controller-finalize-project';
import { ProjectsControllerFinalizeProject$Params } from '../fn/projects-controller/projects-controller-finalize-project';
import { projectsControllerGetProject } from '../fn/projects-controller/projects-controller-get-project';
import { ProjectsControllerGetProject$Params } from '../fn/projects-controller/projects-controller-get-project';
import { projectsControllerGetProjects } from '../fn/projects-controller/projects-controller-get-projects';
import { ProjectsControllerGetProjects$Params } from '../fn/projects-controller/projects-controller-get-projects';
import { projectsControllerLockProject } from '../fn/projects-controller/projects-controller-lock-project';
import { ProjectsControllerLockProject$Params } from '../fn/projects-controller/projects-controller-lock-project';
import { projectsControllerPatchProject } from '../fn/projects-controller/projects-controller-patch-project';
import { ProjectsControllerPatchProject$Params } from '../fn/projects-controller/projects-controller-patch-project';
import { projectsControllerUnlockProject } from '../fn/projects-controller/projects-controller-unlock-project';
import { ProjectsControllerUnlockProject$Params } from '../fn/projects-controller/projects-controller-unlock-project';
import { projectsControllerVerifyProjectLock } from '../fn/projects-controller/projects-controller-verify-project-lock';
import { ProjectsControllerVerifyProjectLock$Params } from '../fn/projects-controller/projects-controller-verify-project-lock';
import { ProjectsResponseGetRecordsList } from '../models/projects-response-get-records-list';
import { UnlockProjectResponse } from '../models/unlock-project-response';
import { UpdateProjectResponse } from '../models/update-project-response';
import { VerifyProjectLockResponse } from '../models/verify-project-lock-response';


/**
 * Represents an API resource for projects manipulation.
 */
@Injectable({ providedIn: 'root' })
export class ProjectsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectsControllerGetProjects()` */
  static readonly ProjectsControllerGetProjectsPath = '/planning-projects/v1-develop/projects';

  /**
   * Gets all project records for current user.
   *         The projects records returned by this endpoint contains only partial - basic data for project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetProjects$Response(params?: ProjectsControllerGetProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectsResponseGetRecordsList>> {
    return projectsControllerGetProjects(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets all project records for current user.
   *         The projects records returned by this endpoint contains only partial - basic data for project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetProjects(params?: ProjectsControllerGetProjects$Params, context?: HttpContext): Observable<ProjectsResponseGetRecordsList> {
    return this.projectsControllerGetProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectsResponseGetRecordsList>): ProjectsResponseGetRecordsList => r.body)
    );
  }

  /** Path part for operation `projectsControllerCreateProject()` */
  static readonly ProjectsControllerCreateProjectPath = '/planning-projects/v1-develop/projects';

  /**
   * Creates new project for current user.
   *         Newly created project is automatically assigned to the calling user account.
   *         Current session informs API about the user. Additionally, newly created
   *         project has always default variant created for it. This prevents
   *         creating projects without variant which would be useless.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerCreateProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerCreateProject$Response(params: ProjectsControllerCreateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateProjectResponse>> {
    return projectsControllerCreateProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new project for current user.
   *         Newly created project is automatically assigned to the calling user account.
   *         Current session informs API about the user. Additionally, newly created
   *         project has always default variant created for it. This prevents
   *         creating projects without variant which would be useless.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerCreateProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerCreateProject(params: ProjectsControllerCreateProject$Params, context?: HttpContext): Observable<CreateProjectResponse> {
    return this.projectsControllerCreateProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateProjectResponse>): CreateProjectResponse => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetProject()` */
  static readonly ProjectsControllerGetProjectPath = '/planning-projects/v1-develop/projects/{id}';

  /**
   * Gets a project record by given ID. The returned project contains
   *         all project variants and its plannings with corresponding products. This endpoint <b>doesn't</b>
   *         return any application-specific data and additional fields for plannings. TO get fully
   *         detailed planning data, we need to call separate endpoint.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetProject$Response(params: ProjectsControllerGetProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectResponseGetById>> {
    return projectsControllerGetProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets a project record by given ID. The returned project contains
   *         all project variants and its plannings with corresponding products. This endpoint <b>doesn't</b>
   *         return any application-specific data and additional fields for plannings. TO get fully
   *         detailed planning data, we need to call separate endpoint.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetProject(params: ProjectsControllerGetProject$Params, context?: HttpContext): Observable<ProjectResponseGetById> {
    return this.projectsControllerGetProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectResponseGetById>): ProjectResponseGetById => r.body)
    );
  }

  /** Path part for operation `projectsControllerDeleteProject()` */
  static readonly ProjectsControllerDeleteProjectPath = '/planning-projects/v1-develop/projects/{id}';

  /**
   * Removes the project physically from database.
   *         Project will be not available after that operation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerDeleteProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerDeleteProject$Response(params: ProjectsControllerDeleteProject$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteProjectResponse>> {
    return projectsControllerDeleteProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the project physically from database.
   *         Project will be not available after that operation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerDeleteProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerDeleteProject(params: ProjectsControllerDeleteProject$Params, context?: HttpContext): Observable<DeleteProjectResponse> {
    return this.projectsControllerDeleteProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteProjectResponse>): DeleteProjectResponse => r.body)
    );
  }

  /** Path part for operation `projectsControllerPatchProject()` */
  static readonly ProjectsControllerPatchProjectPath = '/planning-projects/v1-develop/projects/{id}';

  /**
   * Partially updates a project with given ID, using
   *         partial project structure. It's worth to know that the PATCH-ing logic
   *         contains also a lot of validators that prevents from creating invalid
   *         projects structure. Probably not all cases are covered but we're doing our best.
   *         In some cases, some updates requires additional fields
   *         to be in partial patch object, to detect final state of the project and
   *         prevent doing invalid updates.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerPatchProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerPatchProject$Response(params: ProjectsControllerPatchProject$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateProjectResponse>> {
    return projectsControllerPatchProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Partially updates a project with given ID, using
   *         partial project structure. It's worth to know that the PATCH-ing logic
   *         contains also a lot of validators that prevents from creating invalid
   *         projects structure. Probably not all cases are covered but we're doing our best.
   *         In some cases, some updates requires additional fields
   *         to be in partial patch object, to detect final state of the project and
   *         prevent doing invalid updates.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerPatchProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerPatchProject(params: ProjectsControllerPatchProject$Params, context?: HttpContext): Observable<UpdateProjectResponse> {
    return this.projectsControllerPatchProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateProjectResponse>): UpdateProjectResponse => r.body)
    );
  }

  /** Path part for operation `projectsControllerFinalizeProject()` */
  static readonly ProjectsControllerFinalizeProjectPath = '/planning-projects/v1-develop/projects/{id}/finalize';

  /**
   * Finalizes the project based on current status.
   *         New status will be set based on current status and other conditions.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFinalizeProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFinalizeProject$Response(params: ProjectsControllerFinalizeProject$Params, context?: HttpContext): Observable<StrictHttpResponse<FinalizeProjectResponse>> {
    return projectsControllerFinalizeProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Finalizes the project based on current status.
   *         New status will be set based on current status and other conditions.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFinalizeProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFinalizeProject(params: ProjectsControllerFinalizeProject$Params, context?: HttpContext): Observable<FinalizeProjectResponse> {
    return this.projectsControllerFinalizeProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<FinalizeProjectResponse>): FinalizeProjectResponse => r.body)
    );
  }

  /** Path part for operation `projectsControllerDuplicateProject()` */
  static readonly ProjectsControllerDuplicateProjectPath = '/planning-projects/v1-develop/projects/{id}/duplicate';

  /**
   * Duplicates the project and recalculates heat loads if needed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerDuplicateProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerDuplicateProject$Response(params: ProjectsControllerDuplicateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicateProjectResponse>> {
    return projectsControllerDuplicateProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Duplicates the project and recalculates heat loads if needed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerDuplicateProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerDuplicateProject(params: ProjectsControllerDuplicateProject$Params, context?: HttpContext): Observable<DuplicateProjectResponse> {
    return this.projectsControllerDuplicateProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateProjectResponse>): DuplicateProjectResponse => r.body)
    );
  }

  /** Path part for operation `projectsControllerVerifyProjectLock()` */
  static readonly ProjectsControllerVerifyProjectLockPath = '/planning-projects/v1-develop/projects/{id}/lock';

  /**
   * Verifies the project lock.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerVerifyProjectLock()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerVerifyProjectLock$Response(params: ProjectsControllerVerifyProjectLock$Params, context?: HttpContext): Observable<StrictHttpResponse<VerifyProjectLockResponse>> {
    return projectsControllerVerifyProjectLock(this.http, this.rootUrl, params, context);
  }

  /**
   * Verifies the project lock.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerVerifyProjectLock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerVerifyProjectLock(params: ProjectsControllerVerifyProjectLock$Params, context?: HttpContext): Observable<VerifyProjectLockResponse> {
    return this.projectsControllerVerifyProjectLock$Response(params, context).pipe(
      map((r: StrictHttpResponse<VerifyProjectLockResponse>): VerifyProjectLockResponse => r.body)
    );
  }

  /** Path part for operation `projectsControllerLockProject()` */
  static readonly ProjectsControllerLockProjectPath = '/planning-projects/v1-develop/projects/{id}/lock';

  /**
   * Locks the project or verifies if the project is already locked.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerLockProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerLockProject$Response(params: ProjectsControllerLockProject$Params, context?: HttpContext): Observable<StrictHttpResponse<LockProjectResponse>> {
    return projectsControllerLockProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Locks the project or verifies if the project is already locked.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerLockProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerLockProject(params: ProjectsControllerLockProject$Params, context?: HttpContext): Observable<LockProjectResponse> {
    return this.projectsControllerLockProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<LockProjectResponse>): LockProjectResponse => r.body)
    );
  }

  /** Path part for operation `projectsControllerUnlockProject()` */
  static readonly ProjectsControllerUnlockProjectPath = '/planning-projects/v1-develop/projects/{id}/unlock';

  /**
   * Unlocks the project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerUnlockProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerUnlockProject$Response(params: ProjectsControllerUnlockProject$Params, context?: HttpContext): Observable<StrictHttpResponse<UnlockProjectResponse>> {
    return projectsControllerUnlockProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlocks the project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerUnlockProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerUnlockProject(params: ProjectsControllerUnlockProject$Params, context?: HttpContext): Observable<UnlockProjectResponse> {
    return this.projectsControllerUnlockProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnlockProjectResponse>): UnlockProjectResponse => r.body)
    );
  }

}
