/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ProjectSimulationResult } from '../models/project-simulation-result';
import { simulationsControllerProjectSimulation } from '../fn/simulations-controller/simulations-controller-project-simulation';
import { SimulationsControllerProjectSimulation$Params } from '../fn/simulations-controller/simulations-controller-project-simulation';


/**
 * Represents an API resource used to generate variety of simulations.
 */
@Injectable({ providedIn: 'root' })
export class SimulationsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `simulationsControllerProjectSimulation()` */
  static readonly SimulationsControllerProjectSimulationPath = '/planning-projects/v1-develop/simulations/{id}';

  /**
   * Returns simulation results for project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `simulationsControllerProjectSimulation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  simulationsControllerProjectSimulation$Response(params: SimulationsControllerProjectSimulation$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectSimulationResult>> {
    return simulationsControllerProjectSimulation(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns simulation results for project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `simulationsControllerProjectSimulation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  simulationsControllerProjectSimulation(params: SimulationsControllerProjectSimulation$Params, context?: HttpContext): Observable<ProjectSimulationResult> {
    return this.simulationsControllerProjectSimulation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectSimulationResult>): ProjectSimulationResult => r.body)
    );
  }

}
